<template>
<div class="container">
  <div class="p-md-5 py-5 mb-4 bg-white rounded-3">
    <div class="container-fluid py-1" id="info-container">
      <h1>关于</h1>
      <hr />
      <h4 class="my-3">介绍</h4>
      <p>这个网页收集了狐狐直播间弹幕和礼物的数据，然后通过几类页面展示了出来。点左上角的<u>冰山一角</u>回到主页~</p>
      <p><b>数据总览页面</b>从直播间的视角展示数据，在选择的时间段内对弹幕、礼物、醒目留言、舰团进行了统计和用户排名。</p>
      <p><b>搜索页面</b>可以查询在直播间出现过的所有<b>观众</b>、<b>弹幕</b>和<b>醒目留言</b>数据。搜索弹幕时如果不填关键词可以获取选定时间内的所有数据！在这里搜索观众是模糊搜索，只要用户名包含查询词就会显示出来。并且如果观众改了名，用之前的旧名字也可以查到~点击用户名就会跳转到观众页面显示详细数据！</p>
      <p><b>趋势页面</b>可以查询直播间人气等随时间的变化情况，也可以查某个观众发的弹幕量的变化趋势啥的！<s>可以抓谁没来看直播（不是）</s>这个页面显示的数据都是直播期间的，如果某一天没直播，那一天的数据就不会出现在图表里~</p>
      <p><b>观众页面</b>从一个观众的视角展示这名观众在直播间里的弹幕、礼物、舰团和醒目留言详细数据。这个页面还会显示这名观众在选定时间范围内的曾用名和曾用牌子，这下就不怕观众改名不知道是谁了！<s>（也能知道这人都D了谁）</s>并且在整个网站里，只要是显示观众名的地方，点一下就会跳转到这个页面！</p>
      <h4 class="my-3">扩展玩法？</h4>
      <p><b>需要一段时间内上舰列表</b>？到<u>数据总览视图</u>，日期为想要的时间，点击搜索！查看<u>新增舰团列表</u>！（纪念回或每月发福利参考利器！）</p>
      <p><b>今天发弹幕最多的人都发了些什么弹幕</b>？到<u>数据总览视图</u>，日期为今日，点击获取数据。<u>发弹幕最多的观众</u>中找到第一个人，点击蓝字，自动跳转到观众视图。点击<u>弹幕列表</u>即可！</p>
      <p><b>有个id很熟悉，但忘了全名</b>？到<u>搜索视图</u>，选择<u>观众查找</u>，输入记得的一部分id，点击查找！</p>
      <p><b>怀疑有个观众改名了</b>？到<u>搜索视图</u>，选择<u>观众查找</u>，输入现在的id，点击查找！找到id全名后点击蓝字，自动跳转到观众视图。点击获取数据，查看<u>曾用名</u>一栏~</p>
      <h4 class="my-3">有建议？</h4>
      <p>哪里不好或者有啥需要的功能请直接QQ踹我！本人什么都会点，就是效率O(n!)</p>
      <h4 class="my-3">数据特殊情况</h4>
      <p>从 https://matsuri.icu/ 网站经过老师的同意薅来了之前没有收集的数据！但是这个网站的数据也并不完整甚至有一定的错误，因此之前的数据只能用作参考，有总比没有好！不过由于有时候爬虫服务器不稳定（自学python技术烂的不行）有时候数据收集不全...抱歉...以下是数据收集情况，大部分都是正确的！还是具备一定参考价值的！在试图优化脚本了，希望以后可以不出错！</p>
      <ul>
        <li>2021-02-10 至 2021-11-07 无观众牌子数据，无银瓜子礼物数据 这个区间内的数据存在一定的不完整，不完整的情况尚不明确...</li>
        <li>2021-11-08 至今 全部数据</li>
        <li>2021-12-28 部分数据监听中断（复盘：live_listener.py内存溢出崩溃）</li>
      </ul>
      <h4 class="my-3">表白</h4>
      <p>感谢狐狐一直以来的陪伴！关注狐狐快一年了，期间也断断续续摸鱼了一些项目。每个人表达喜欢的方式都各有不同，我的话更多是用技术力来陪伴狐狐一起面对未知的未来吧。工作以后每天都面临着形形色色的人，在职场的战场里爬来滚去（p.s真看不懂女同事的心思），虽然经常加班错过狐狐直播，但是偶尔可以在狐狐的直播间里逃避一下下也是超级棒的，狐狐工作之后是不是也会增加很多烦恼呢，to b的工作着实令人头秃。新的一年祝狐狐事业直播双丰收，会一直单推狐狐下去哒</p>
    </div>
  </div>
</div>
</template>


<script>
export default {
  name: 'Info',
  components: {
  }
}
</script>

<style>
  #info-container p{text-indent:2em}
</style>