import parse from '@/parse_data.js'
import Vue from 'vue'
var dayjs = require('dayjs');
var schema = function(desc){
    var uid = parseInt(window.data.user_picker.preserved.uid)
    var display = window.data
    return {
        'view_max': {},
        'view_livetime': {},
        'view_trend': {},
        'interact_trend': {},
        'rmb_total': {},
        'rmb_captain': {},
        'rmb_gachi': {},
        'rmb_other': {},
        'rmb_viewer': {'page': display.data_view_total_gold.viewer_list.page},
        'rmb_trend': {},
        'captain_total': {},
        'captain_num': {},
        'captain_all_list': {'desc':desc, 'page': display.data_view_captain.viewer_list.page, 'captain_type': display.data_view_captain.viewer_list.cpt_type},
        'superchat_total': {},
        'superchat_captain': {},
        'superchat_gachi': {},
        'superchat_other': {},
        'superchat_viewer': {'page': display.data_view_superchat.viewer_list.page},
        'superchat_all_list': {'desc':desc, 'page': display.data_view_superchat.sc_list.page},
        'superchat_search': {'desc':desc, 'page': display.search_superchat.page, 'per_page': 50},
        'gold_coin_gift_total': {},
        'gold_coin_gift_captain': {},
        'gold_coin_gift_gachi': {},
        'gold_coin_gift_other': {},
        'gold_coin_gift_viewer': {'page': display.data_view_gold_coin.viewer_list.page},
        'gold_coin_gift_gift': {},
        'silver_coin_gift_total': {},
        'silver_coin_gift_captain': {},
        'silver_coin_gift_gachi': {},
        'silver_coin_gift_other': {},
        'silver_coin_gift_viewer': {'page': display.data_view_silver_coin.viewer_list.page},
        'silver_coin_gift_gift': {},
        'comment_total': {},
        'comment_captain': {},
        'comment_gachi': {},
        'comment_other': {},
        'comment_viewer': {'page': display.data_view_comment.viewer_list.page, },
        'comment_search': {'desc':desc, 'keyword': '', 'page': display.search_comment.page, 'per_page': 50},
        'comment_trend': {},
        'user_search_name': {'keyword': '雪狐桑'},
        'user_comment_num': {'uid': uid},
        'user_comment_list': {'desc':desc, 'uid': uid},
        'user_comment_trend': {'uid': uid},
        'user_rmb_total': {'uid': uid},
        'user_rmb_trend': {'uid': uid},
        'user_gold_coin_gift_total': {'uid': uid},
        'user_gold_coin_gift_list': {'desc':desc, 'uid': uid},
        'user_silver_coin_gift_total': {'uid': uid},
        'user_silver_coin_gift_list': {'desc':desc, 'uid': uid},
        'user_silver_coin_gift_trend': {'uid': uid},
        'user_superchat_num': {'uid': uid},
        'user_superchat_total': {'uid': uid},
        'user_superchat_list': {'desc':desc, 'uid': uid},
        'user_captain_total': {'uid': uid},
        'user_captain_num': {'uid': uid},
        'user_captain_list': {'desc':desc, 'uid': uid},
        'user_interact_trend': {'uid': uid},
        'user_former_name': {'uid': uid},
        'user_current_name': {'uid': uid},
        'user_former_tag': {'uid': uid},
    }
}

function cal_date_range(all=false){
    var data = window.data.date_picker
    var range_type = data.time_range
    var start = new Date()
    var end = new Date()
    var t
    if ( range_type == 'day' ){
        start = dayjs(data.day)
        end = dayjs(data.day)
    }
    else if( range_type == 'week' ){
        t = dayjs().year(data.week.year).week(data.week.week)
        start = t.clone().startOf('isoWeek')
        end = t.clone().endOf('isoWeek')
    }
    else if( range_type == 'month' ){
        t = dayjs().year(data.month.year).month(data.month.month)
        start = t.clone().startOf('month')
        end = t.clone().endOf('month')
    }
    else if( range_type == 'year' ){
        t = dayjs().year(data.year)
        start = t.clone().startOf('year')
        end = t.clone().endOf('year')
    }
    else if( range_type == 'all' ){
        start = dayjs(new Date(2021, 1, 13))
        end = dayjs()
    }
    else if( range_type == 'user_define' ){
        start = dayjs(data.start)
        end = dayjs(data.end)
    }
    if(all){
        start = dayjs(new Date(2021, 1, 13))
        end = dayjs()
    }
    start = start.hour(0).minute(0).second(0).millisecond(0)
    end = end.hour(23).minute(59).second(59).millisecond(999)
    data.date_range = [start.valueOf(), end.valueOf()]
    data.init = true
    return data.date_range
}

var verify = function(hash){
    var client = new XMLHttpRequest()
    client.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200 && this.response != null) {
            let target = window.data.home
            let rst = this.response
            target.loading = false
            if (rst['valid']){
                //设置cookie
                target.verified = true
                Vue.$cookies.set('password', rst['password'])
                target.password = ''
            }
            else{
                alert('口令不对！')
                target.password = ''
                target.verified = false
            }
        }
    }
    client.responseType = "json"
    client.open("POST", "/api/verify")
    client.setRequestHeader("Content-type","application/json")
    client.send(JSON.stringify({'password': hash}))
}

var query = function(
    query_l, 
    params = {
        vid: window.data.vup_picker.vid, 
        date_range: window.data.date_picker.date_range, 
        desc: false,
        live: 'all'
    },
    callback = null
){
    if (params.desc==null) params.desc=false
    if (params.live==null) params.live='all'
    if (window.data.date_picker.init == false) return
    var client = new XMLHttpRequest()
    client.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200 && this.response != null) {
            let qs = this.response
            console.log(qs)
            if (callback==null){
                for (let q of qs){
                    parse.parse[q['name']](q)
                }
            }
            else
                callback(qs)
        }
        else if (this.readyState == 4 && this.status == 400) {
            // 参数错误
            alert('查询参数错误！八成是日期写太早了')
            window.location.replace('/')
        }
        else if (this.readyState == 4 && this.status == 403) {
            // 参数错误
            alert('你还没说口令诶 不给看！回主页去重输！')
            Vue.$cookies.remove('password')
            window.data.home.verified = false
            window.location.replace('/')
        }
        else if (this.readyState == 4 && this.status == 500) {
            // 出bug了
            alert("出bug了 淦 刷新一下页面吧 我也不知道咋回事")
        }
    }
    client.responseType = "json"
    client.open("POST", "/api/query")
    client.setRequestHeader("Content-type","application/json")

    // 请求参数
    var all = schema(params.desc)
    for (let q of query_l){
        // 添加默认参数
        let item = all[q["method"]]
        for (let k in item){
            if (q[k]==null) q[k] = item[k]
        }
    }
    client.send(JSON.stringify({
        "query": query_l,
        "time_span": params.date_range,
        "live": params.live,
        "rname": params.vid
    }))
}

export default {query, verify, cal_date_range}