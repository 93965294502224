<template>
    <b-card class="m-3">
      <b-card-title>用户信息</b-card-title>
      <b-card-body>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="80%"></b-skeleton>
          </template>
          <div>观众现用名：{{username}}</div>
          <div>观众uid：{{uid}}</div>
          <div><b-link @click="jump_trend">查看观众相关趋势</b-link></div>
          <div><a :href="'https://space.bilibili.com/'+uid" target="__blank">观众b站主页</a></div>
        </b-skeleton-wrapper>
      </b-card-body>
    </b-card>
</template>

<script>
window.data.user_view_info = {
  uid: 1897341,
  username: "...",
  loading: true
}
var data = window.data.user_view_info
export default {
  name: 'user-view-info',
  data: ()=>{
    return data
  },
  methods: {
    jump_trend: function(){
      window.data.trend_user.user_selected = 'uid'
      window.data.trend_user.selected = 'user_comment_trend'
      window.data.trend_user.keyword = this.uid
      window.data.trend_user.preserved.uid = this.uid
      this.$router.push('/trend/user?s=true')
    }
  }
}
</script>
