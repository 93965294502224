<template>
  <div>
    <data-picker/>
    <data-view-popularity/>
    <data-view-total-gold/>
    <data-view-captain/>
    <data-view-superchat/>
    <data-view-gold-coin/>
    <data-view-comment/>
    <data-view-silver-coin/>
  </div>
</template>

<script>
import data_picker from '@/components/Data/picker.vue'
import data_view_popularity from '@/components/Data/view-popularity.vue'
import data_view_captain from '@/components/Data/view-captain.vue'
import data_view_total_gold from '@/components/Data/view-total-gold.vue'
import data_view_gold_coin from '@/components/Data/view-gold-coin.vue'
import data_view_silver_coin from '@/components/Data/view-silver-coin.vue'
import data_view_superchat from '@/components/Data/view-superchat.vue'
import data_view_comment from '@/components/Data/view-comment.vue'

export default {
  name: 'Data',
  components: {
    'data-picker': data_picker,
    'data-view-total-gold': data_view_total_gold,
    'data-view-comment': data_view_comment,
    'data-view-superchat': data_view_superchat,
    'data-view-silver-coin': data_view_silver_coin,
    'data-view-gold-coin': data_view_gold_coin,
    'data-view-captain': data_view_captain,
    'data-view-popularity': data_view_popularity,
  }
}
</script>