<template>
  <div>
    <b-nav tabs align="center">
      <b-nav-item active-class="active" to="/trend/all">总览</b-nav-item>
      <b-nav-item active-class="active" to="/trend/user">单一用户</b-nav-item>
    </b-nav>
    <router-view></router-view>
    <div class="text-secondary m-3">注：该页面显示的数据均为直播期间的数据，未直播的日期已被过滤</div>
  </div>
</template>
<script>

export default {
  name: 'Trend',
  components: {
  }
}
</script>