<template>
    <b-card class="m-3" no-body>
      <b-card-header header-tag="nav">
        <b-nav card-header pills>
          <b-nav-item :active="tabs[0]" v-on:click="select(0)">总收入</b-nav-item>
          <b-nav-item :active="tabs[1]" v-on:click="select(1)">总观众排名</b-nav-item>
        </b-nav>
      </b-card-header>
      <b-card-body v-if="tabs[0]">
        <b-row>
          <div class="col-md-6 text-center my-2">
            <h5>全部观众</h5>
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton width="100%"></b-skeleton>
                <b-skeleton width="80%"></b-skeleton>
              </template>
              <div>￥{{gold.all}}</div>
            </b-skeleton-wrapper>
          </div>
          <div class="col-md-6 text-center my-2">
            <h5>路人 (没挂牌的人)</h5>
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton width="100%"></b-skeleton>
                <b-skeleton width="80%"></b-skeleton>
              </template>
              <div>￥{{gold.other}}</div>
            </b-skeleton-wrapper>
          </div>
        </b-row>
      </b-card-body>
      <b-card-body v-if="tabs[1]">
        <b-skeleton-wrapper :loading="viewer_list.loading">
          <template #loading>
            <b-skeleton width="30%"></b-skeleton>
            <b-skeleton-table
              :rows="viewer_list.items.length+1"
              :columns="3"
              :table-props="{bordered: true}"
              :small="true"
            ></b-skeleton-table>
          </template>
          <div class="row">
            <div class="col text-secondary">共{{viewer_list.total}}名观众</div>
          </div>
          <table class="table table-sm table-sm table-bordered table-hover" id="data_view_rmb">
            <thead class="thead-light">
              <tr>
                <th>排名</th>
                <th>用户</th>
                <th>￥￥￥</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user, index) in viewer_list.items" :key="user.rank">
                <td>{{user.rank}}</td>
                <td><user-popover prefix="totalgold" :id="index" :user="user.user"/></td>
                <td>￥{{user.total_price}}</td>
              </tr>
            </tbody>
          </table>
          <b-pagination
            v-model="viewer_list.page"
            :total-rows="viewer_list.total"
            :per-page="20"
            first-number
            last-number
            align="center"
            v-on:change="change_page"
          ></b-pagination>
        </b-skeleton-wrapper>
      </b-card-body>
    </b-card>
</template>

<script>
window.data.data_view_total_gold = {
  tabs: [true, false],
  loading: true,
  gold: {
    all: 0,
    other: 0
  },
  viewer_list: {
    page: 1,
    total: 1,
    loading: true,
    init: false,
    items: [
      {
        rank: 1,
        total_price: 9900,
        user: {
          uid: 198165375,
          name: '冰绒冷焰',
          medal_name: '雪狐咕',
          medal_level: 20,
          medal_captain: 3,
        }
      }
    ]
  }
}
var data = window.data.data_view_total_gold
var query_params = window.data.data_picker.preserved
import query from '@/query.js'
export default {
  name: 'data-view-total-gold',
  data: ()=>{
    return data
  },
  methods: {
    select: (x)=>{
      if (x == 1 && data.viewer_list.init == false){
        query.query([{"method":"rmb_viewer"}], query_params)
        data.viewer_list.init = true
        data.viewer_list.loading = true
      }
      var tabs = [false, false]
      tabs[x] = true
      data.tabs = tabs
    },
    change_page: page=>{
      document.getElementById("data_view_rmb").scrollIntoView()
      query.query([{"method":"rmb_viewer", "page": page}], query_params)
    }
  }
}
</script>
