<template>
  <div>
    <user-picker/>
    <user-view-info/>
    <user-view-comment/>
    <user-view-gift/>
    <user-view-captain/>
    <user-view-superchat/>
    <user-view-former/>
  </div>
</template>

<script>
import user_picker from '@/components/User/picker.vue'
import user_view_info from '@/components/User/view-info.vue'
import user_view_comment from '@/components/User/view-comment.vue'
import user_view_gift from '@/components/User/view-gift.vue'
import user_view_superchat from '@/components/User/view-superchat.vue'
import user_view_former from '@/components/User/view-former.vue'
import user_view_captain from '@/components/User/view-captain.vue'

export default {
  name: 'User',
  components: {
    'user-picker': user_picker,
    'user-view-info': user_view_info,
    'user-view-comment': user_view_comment,
    'user-view-gift': user_view_gift,
    'user-view-superchat': user_view_superchat,
    'user-view-former': user_view_former,
    'user-view-captain': user_view_captain,
  }
}
</script>