<template>
    <b-card class="m-3" no-body>
      <b-card-header header-tag="nav">
        <b-nav card-header pills>
          <b-nav-item :active="tabs[0]" v-on:click="select(0)">曾用名</b-nav-item>
          <b-nav-item :active="tabs[1]" v-on:click="select(1)">曾用牌子</b-nav-item>
        </b-nav>
      </b-card-header>
      <b-card-body v-if="tabs[0]">
        <b-skeleton-wrapper :loading="name_list.loading">
          <template #loading>
            <b-skeleton width="30%"></b-skeleton>
            <b-skeleton-table
              :rows="name_list.items.length+1"
              :columns="2"
              :table-props="{bordered: true}"
              :small="true"
            ></b-skeleton-table>
          </template>
          <table class="table table-sm table-bordered table-hover">
            <thead class="thead-light">
              <tr>
                <th>最早出现时间</th>
                <th>名字</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="name in name_list.items" :key="name.time">
                <td>{{name.time}}</td>
                <td>{{name.name}}</td>
              </tr>
            </tbody>
          </table>
        </b-skeleton-wrapper>
      </b-card-body>
      <b-card-body v-if="tabs[1]">
        <b-skeleton-wrapper :loading="medal_list.loading">
          <template #loading>
            <b-skeleton width="30%"></b-skeleton>
            <b-skeleton-table
              :rows="medal_list.items.length+1"
              :columns="2"
              :table-props="{bordered: true}"
              :small="true"
            ></b-skeleton-table>
          </template>
          <table class="table table-sm table-bordered table-hover">
            <thead class="thead-light">
              <tr>
                <th>最早出现时间</th>
                <th>牌子</th>
                <th>等级</th>
                <th>舰团</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="medal in medal_list.items" :key="medal.time">
                <td>{{medal.time}}</td>
                <td>{{medal.name}}</td>
                <td>{{medal.level}}</td>
                <td>{{medal.captain}}</td>
              </tr>
            </tbody>
          </table>
        </b-skeleton-wrapper>
      </b-card-body>
    </b-card>
</template>

<script>
window.data.user_view_former = {
  tabs: [true, false],
  name_list: {
    page: 1,
    total: 1,
    init: false,
    loading: true,
    items: [
      {
        time: 'YYYY/MM/DD HH:mm:ss',
        name: '还没加载出来呢',
      }
    ]
  },
  medal_list: {
    page: 1,
    total: 1,
    init: false,
    loading: true,
    items: [
      {
        time: 'YYYY/MM/DD HH:mm:ss',
        name: '还没加载出来呢',
        level: 0,
        captain: '--'
      }
    ]
  }
}
var data = window.data.user_view_former
export default {
  name: 'user-view-former',
  data: ()=>{
    return data
  },
  methods: {
    select: (x)=>{
      var tabs = [false, false]
      tabs[x] = true
      data.tabs = tabs
    }
  }
}
</script>
