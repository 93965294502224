<template>
<div>
  <div class="p-4 p-md-5 m-3 bg-white row">
    <b-col md="12" class="py-3"><h1>数据总览</h1></b-col>
    <vup-picker class="col-md-3 py-3" />
    <date-picker class="col-md-5 py-3" />
    <div class="col-md-4 py-3">
      <b-form-group>
        <h5 class="pb-2">时间范围：</h5>
        <b-form-radio-group v-model="live" stacked>
          <b-form-radio value="all">全部</b-form-radio>
          <b-form-radio value="on_live">仅直播期间</b-form-radio>
          <b-form-radio value="off_live">非直播期间</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </div>
    <div class="col py-3">
      <b-button block variant="primary" :disabled="loading && init" @click="get_data()">{{search_text}}</b-button>
    </div>
  </div>
  <div class="m-3 text-secondary row" v-if="init">
    <b-col md="auto">数据来源：{{vup_text}}</b-col>
    <b-col md="auto">日期范围：{{date_text}}</b-col>
    <b-col md="auto">时间范围：{{live_text}}</b-col>
  </div>
</div>
</template>

<script>
import date_picker from '@/components/Date-picker.vue'
import vup_picker from '@/components/Vup-picker.vue'
import query from '@/query.js'
var dayjs = require('dayjs');
window.data.data_picker = {
  live: 'all',
  init: false,
  loading: true,
  preserved: {
    vid: '',
    date_range: ['', ''],
    live: ''
  }
}
var data = window.data.data_picker
export default {
  name: 'data-picker',
  components: {
    'date-picker': date_picker,
    'vup-picker': vup_picker,
  },
  data: () => {
    return data
  },
  methods: {
    get_data: function(){
      // 保存请求参数
      this.preserved.vid = window.data.vup_picker.vid
      this.preserved.date_range = query.cal_date_range()
      this.preserved.live = this.live

      // 创建请求
      let display = window.data
      // 基础列表
      let qlist = [
          {"method":"view_max"},
          {"method":"interact_num"},
          {"method":"view_livetime"},
          {"method":"rmb_total"},
          {"method":"rmb_other"},
          {"method":"captain_total"},
          {"method":"captain_num"},
          {"method":"superchat_total"},
          {"method":"superchat_other"},
          {"method":"gold_coin_gift_total"},
          {"method":"gold_coin_gift_other"},
          {"method":"silver_coin_gift_total"},
          {"method":"silver_coin_gift_other"},
          {"method":"comment_total"},
          {"method":"comment_captain"},
          {"method":"comment_gachi"}
      ]
      // 附加请求（根据展开的列表判断）
      if (display.data_view_total_gold.tabs[1]==true) qlist.push({"method": "rmb_viewer"})
      if (display.data_view_captain.tabs[1]==true) qlist.push({"method": "captain_all_list"})
      if (display.data_view_superchat.tabs[1]==true) qlist.push({"method": "superchat_viewer"})
      if (display.data_view_superchat.tabs[2]==true) qlist.push({"method": "superchat_all_list"})
      if (display.data_view_gold_coin.tabs[1]==true) qlist.push({"method": "gold_coin_gift_viewer"})
      if (display.data_view_gold_coin.tabs[2]==true) qlist.push({"method": "gold_coin_gift_gift"})
      if (display.data_view_silver_coin.tabs[1]==true) qlist.push({"method": "silver_coin_gift_viewer"})
      if (display.data_view_silver_coin.tabs[2]==true) qlist.push({"method": "silver_coin_gift_gift"})
      if (display.data_view_comment.tabs[1]==true) qlist.push({"method": "comment_viewer"})

      // 初始化列表
      this.init = true
      display.data_view_popularity.new_viewer_num_init = false
      display.data_view_total_gold.viewer_list.init = display.data_view_total_gold.tabs[1]
      display.data_view_captain.viewer_list.init = display.data_view_captain.tabs[1]
      display.data_view_superchat.viewer_list.init = display.data_view_superchat.tabs[1]
      display.data_view_superchat.sc_list.init = display.data_view_superchat.tabs[2]
      display.data_view_gold_coin.viewer_list.init = display.data_view_gold_coin.tabs[1]
      display.data_view_gold_coin.gift_list.init = display.data_view_gold_coin.tabs[2]
      display.data_view_silver_coin.viewer_list.init = display.data_view_silver_coin.tabs[1]
      display.data_view_silver_coin.gift_list.init = display.data_view_silver_coin.tabs[2]
      display.data_view_comment.viewer_list.init = display.data_view_comment.tabs[1]
      // 所有列表回到第一页
      display.data_view_total_gold.viewer_list.page = 1
      display.data_view_captain.viewer_list.page = 1
      display.data_view_superchat.viewer_list.page = 1
      display.data_view_superchat.sc_list.page = 1
      display.data_view_gold_coin.viewer_list.page = 1
      display.data_view_gold_coin.gift_list.page = 1
      display.data_view_silver_coin.viewer_list.page = 1
      display.data_view_silver_coin.gift_list.page = 1
      display.data_view_comment.viewer_list.page = 1
      // 所有列表时间倒序
      display.data_view_superchat.sc_list.desc = false
      display.data_view_captain.viewer_list.desc = false
      // 所有项目loading
      this.loading = true
      display.data_view_popularity.loading = true
      display.data_view_popularity.new_viewer_num_loading = true
      display.data_view_total_gold.loading = true
      display.data_view_total_gold.viewer_list.loading = true
      display.data_view_captain.loading = true
      display.data_view_captain.viewer_list.loading = true
      display.data_view_superchat.loading = true
      display.data_view_superchat.viewer_list.loading = true
      display.data_view_superchat.sc_list.loading = true
      display.data_view_gold_coin.loading = true
      display.data_view_gold_coin.viewer_list.loading = true
      display.data_view_gold_coin.gift_list.loading = true
      display.data_view_silver_coin.loading = true
      display.data_view_silver_coin.viewer_list.loading = true
      display.data_view_silver_coin.gift_list.loading = true
      display.data_view_comment.loading = true
      display.data_view_comment.viewer_list.loading = true

      // 请求
      query.query(qlist, this.preserved)
    },
  },
  computed: {
    search_text: function(){
      if (this.loading && this.init) return '获取中...'
      else return '获取数据！'
    },
    vup_text: function(){
      var xuehu = 'xuehu';
      if (this.preserved.vid== xuehu) return '雪狐'
      return '等待中'
    },
    date_text: function(){
      var start = dayjs(this.preserved.date_range[0])
      var end = dayjs(this.preserved.date_range[1])
      return start.format('YYYY-MM-DD')+'至'+end.format('YYYY-MM-DD')
    },
    live_text: function(){
      if (this.preserved.live=='on_live') return '仅直播期间'
      if (this.preserved.live=='off_live') return '非直播期间'
      return '全部'
    }
  }
}
</script>
