<template>
  <b-card header="直播信息" class="m-3">
    <b-row>
      <div class="col-md-3 text-center my-4">
        <h5>总直播时长</h5>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="80%"></b-skeleton>
          </template>
          <div>{{live_length}}</div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-3 text-center my-4">
        <h5>总直播天数</h5>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="80%"></b-skeleton>
          </template>
          <div>共 {{live_days}} 天</div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-6 text-center my-4">
        <h5>直播时间</h5>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="80%"></b-skeleton>
          </template>
          <div v-for="time in live_time.slice(0,3)" :key="time.start">
            {{time.start}} 至 {{time.end}} ({{time.hour}}小时{{time.minute}}分钟)
          </div>
          <b-collapse v-model="visible">
            <div v-for="time in live_time.slice(3)" :key="time.start">
              {{time.start}} 至 {{time.end}} ({{time.hour}}小时{{time.minute}}分钟)
            </div>
          </b-collapse>
          <b-button
            block
            variant="outline-secondary"
            size="sm"
            v-if="live_time.length > 3"
            @click="visible = !visible"
            >
            {{collapse}}
          </b-button>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-3 text-center my-4">
        <h5>人气最大值</h5>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="80%"></b-skeleton>
          </template>
          <div>{{max}}</div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-3 text-center my-4">
        <h5>互动观众数量</h5>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="80%"></b-skeleton>
          </template>
          <div>{{interact_num}}</div>
        </b-skeleton-wrapper>
      </div>
      <div class="col-md-6 text-center my-4">
        <h5>首次互动观众的数量</h5>
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <b-skeleton width="100%"></b-skeleton>
            <b-skeleton width="80%"></b-skeleton>
          </template>
          <b-skeleton-wrapper :loading="new_viewer_num_loading">
            <template #loading>
              <b-skeleton width="100%"></b-skeleton>
              <b-skeleton width="80%"></b-skeleton>
            </template>
            <div>{{new_viewer_num}}</div>
          </b-skeleton-wrapper>
          <b-button
            variant="outline-secondary"
            size="sm"
            v-if="new_viewer_num_loading"
            @click="get_new_viewer_num()"
            :disabled="new_viewer_num_loading && new_viewer_num_init"
            >
            {{new_viewer_num_text}}
          </b-button>
        </b-skeleton-wrapper>
      </div>
    </b-row>
  </b-card>
</template>

<script>
import query from '@/query.js'
window.data.data_view_popularity = {
  live_length: '00:00:00',
  live_days: 0,
  loading: true,
  visible: false,
  live_time: [
    {
      start: '00:00:00',
      end: '00:00:00',
    }
  ],
  max: 0,
  interact_num: 0,
  new_viewer_num: 0,
  new_viewer_num_loading: true,
  new_viewer_num_init: false,
}
var data = window.data.data_view_popularity
export default {
  name: 'data-view-popularity',
  data: ()=>{
    return data
  },
  computed: {
    collapse: ()=>{
      if (data.visible) return '收起'
      else return '...展开'
    },
    new_viewer_num_text: function(){
      if (this.new_viewer_num_init && this.new_viewer_num_loading) return "获取中..."
      else return "点击获取..."
    }
  },
  methods: {
    get_new_viewer_num: function(){
      // 初始化
      this.new_viewer_num_init = true
      // 加载中
      this.new_viewer_num_loading = true
      // 请求
      query.query([{"method":"first_interact_num"}], window.data.data_picker.preserved)
    }
  }
}
</script>
