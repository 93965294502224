<template>
  <div>
    <h5 class="pb-2">日期范围：</h5>
    <b-form-group>
      <b-form-radio-group v-model="time_range">
        <b-form-radio value="day" v-if="!exclude_day">单日</b-form-radio>
        <b-form-radio value="week">单周</b-form-radio>
        <b-form-radio value="month">单月</b-form-radio>
        <b-form-radio value="year">全年</b-form-radio>
        <b-form-radio value="all">全部</b-form-radio>
        <b-form-radio value="user_define">自定义</b-form-radio>
      </b-form-radio-group>
    </b-form-group>
    <div v-if="time_range=='day'">
      <div class="row align-items-center">
        <div class="col-auto">选择：</div>
        <div class="col">
          <b-form-datepicker
            id="start-datepicker"
            v-model="day"
            value-as-date
          ></b-form-datepicker>
          </div>
      </div>
    </div>
    <div v-if="time_range=='week'" class="row p-0 m-0">
      <div class="col-12 pb-3">{{week_str}}</div>
      <div class="input-group mb-3 pr-2" style="width:11rem;">
        <div class="input-group-prepend">
          <button class="btn btn-outline-secondary" type="button" @click="week_change(-1, 0)">&lsaquo;</button>
        </div>
        <div class="input-group-append">
          <label class="input-group-text">{{week.year}} 年</label>
          <button class="btn btn-outline-secondary" type="button" @click="week_change(1, 0)">&rsaquo;</button>
        </div>
      </div>
      <div class="input-group mb-3" style="width:12rem;">
        <div class="input-group-prepend">
          <button class="btn btn-outline-secondary" type="button" @click="week_change(0, -1)">&lsaquo;</button>
        </div>
        <div class="input-group-append">
          <label class="input-group-text">第 {{week.week}} 周</label>
          <button class="btn btn-outline-secondary" type="button" @click="week_change(0, 1)">&rsaquo;</button>
        </div>
      </div>
    </div>
    <div v-if="time_range=='month'" class="row p-0 m-0">
      <div class="input-group mb-3 pr-2" style="width:11rem;">
        <div class="input-group-prepend">
          <button class="btn btn-outline-secondary" type="button" @click="month_change(-1, 0)">&lsaquo;</button>
        </div>
        <div class="input-group-append">
          <label class="input-group-text">{{month.year}} 年</label>
          <button class="btn btn-outline-secondary" type="button" @click="month_change(1, 0)">&rsaquo;</button>
        </div>
      </div>
      <div class="input-group mb-3" style="width:12rem;">
        <div class="input-group-prepend">
          <button class="btn btn-outline-secondary" type="button" @click="month_change(0, -1)">&lsaquo;</button>
        </div>
        <div class="input-group-append">
          <label class="input-group-text">{{month.month+1}} 月</label>
          <button class="btn btn-outline-secondary" type="button" @click="month_change(0, 1)">&rsaquo;</button>
        </div>
      </div>
    </div>
    <div v-if="time_range=='year'" class="row p-0 m-0">
      <div class="input-group mb-3 pr-2" style="width:11rem;">
        <div class="input-group-prepend">
          <button class="btn btn-outline-secondary" type="button" @click="year-=1">&lsaquo;</button>
        </div>
        <div class="input-group-append">
          <label class="input-group-text">{{year}} 年</label>
          <button class="btn btn-outline-secondary" type="button" @click="year+=1">&rsaquo;</button>
        </div>
      </div>
    </div>
    <div v-if="time_range=='user_define'">
      <div class="row align-items-center">
        <div class = "col-auto">起：</div>
        <div class = "col">
          <b-form-datepicker
            id="start-datepicker"
            v-model="start"
            value-as-date
          ></b-form-datepicker>
          </div>
      </div>
      <div class="row mt-2 align-items-center">
        <div class = "col-auto">止：</div>
        <div class = "col">
          <b-form-datepicker
            id="end-datepicker"
            v-model="end"
            value-as-date
          ></b-form-datepicker>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
var dayjs = require('dayjs')
dayjs.extend(require('dayjs/plugin/weekOfYear'))
dayjs.extend(require('dayjs/plugin/isoWeek'))
var today = dayjs()
window.data.date_picker = {
  time_range: 'day',
  day: new Date(),
  week: {
    year: today.year(),
    week: today.week()
  },
  month: {
    year: today.year(),
    month: today.month()
  },
  year: today.year(),
  start: new Date(),
  end: new Date(),
  date_range: [],
  init: false
}
var data = window.data.date_picker
export default {
  name: 'date-picker',
  props: {
    exclude_day: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return data
  },
  methods: {
    week_change: function(y, w){
      var t = dayjs().year(this.week.year+y).week(this.week.week+w)
      this.week.year = t.year()
      this.week.week = t.week()
    },
    month_change: function(y, m){
      var t = dayjs().year(this.month.year+y).month(this.month.month+m)
      this.month.year = t.year()
      this.month.month = t.month()
    }
  },
  computed: {
    week_str: function() {
      var t = dayjs().year(this.week.year).week(this.week.week)
      return t.startOf('isoWeek').format('YYYY年MM月DD日')+' 至 '+t.endOf('isoWeek').format('YYYY年MM月DD日')
    }
  },
  created: function(){
    if (this.exclude_day && data.time_range=="day") data.time_range = "week"
  }
}
</script>