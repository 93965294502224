var dayjs = require('dayjs')
dayjs.extend(require('dayjs/plugin/duration'))
function parse_user(data){
    return {
        uid: data['uid'],
        name: data['username'],
        medal_name: data['medal_name'],
        medal_level: data['medal_level'],
        medal_captain: data['captain'],
    }
}
function parse_time(timestamp){
    return dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss')
}
function number_format(n){
    return new Intl.NumberFormat().format(n)
}
function number_data(data, bit=0, thousands=true, scale=1){
    let o = '无数据'
    if (data!=null) {
        o = (data*scale).toFixed(bit)
        if (thousands) o = number_format(o)
    }
    return o
}
function trend_data(data, target, clear_zero=false){
    target.loading = false
    if (data['rst']==null) {
        target.chartdata = []
        return
    }
    clear_zero=false
    if (clear_zero){
        target.chartdata = data['rst'].filter((d)=>{
            return d[1] > 1
        })
    }
    else target.chartdata = data['rst']
}

var display = window.data
var parse = {
    'view_max': i=>{
        display.data_view_popularity.max = number_data(i['rst'])
        display.data_view_popularity.loading = false
        display.data_picker.loading = false
    },
    'view_livetime': data=>{
        if (data['rst']==null || data['rst'].length == 0) {
            display.data_view_popularity.live_time = []
            display.data_view_popularity.live_length = '0小时 0分钟'
            return
        }
        let ms = 0
        let days = new Set()
        display.data_view_popularity.live_time = []
        let start, end, hour, minute
        for (let t of data['rst']){
            if (t['end']==null) t['end'] = dayjs().valueOf()
            let live_duration = t['end']-t['start']
            ms += live_duration
            start = dayjs(t['start']).format('YYYY-MM-DD HH:mm')
            end = dayjs(t['end']).format('HH:mm')
            hour = Math.floor(dayjs.duration(live_duration).asHours())
            minute = Math.floor(dayjs.duration(live_duration).minutes())
            days.add(dayjs(t['start']).format('YYYY-MM-DD'))
            display.data_view_popularity.live_time.push({
                'start': start,
                'end': end,
                'hour': hour,
                'minute': minute
            })
        }
        let d = dayjs.duration(ms)
        display.data_view_popularity.live_length = Math.floor(d.asHours())+'小时 '+Math.floor(d.minutes())+'分钟'
        display.data_view_popularity.live_days = days.size
    },
    'interact_num': i=>{
        display.data_view_popularity.interact_num = number_data(i['rst'])
    },
    'view_trend': data=>{trend_data(data, display.trend_all, true)},
    'interact_trend': data=>{trend_data(data, display.trend_all, true)},
    'first_interact_num': i=>{
        display.data_view_popularity.new_viewer_num = number_data(i['rst'])
        display.data_view_popularity.new_viewer_num_loading = false
    },
    'rmb_total': i=>{
        display.data_view_total_gold.gold.all = number_data(i['rst'], 2, true, 0.001)
        display.data_view_total_gold.loading = false
    },
    'rmb_other': i=>{display.data_view_total_gold.gold.other = number_data(i['rst'], 2, true, 0.001)},
    'rmb_viewer': data=>{
        let target = display.data_view_total_gold.viewer_list
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            target.total = 0
            return
        }
        target.total = data['num']
        var i = 1
        for (let u of data['rst']){
            target.items.push({
                user: parse_user(u),
                total_price: number_data(u['sum'], 2, true, 0.001),
                rank: i+(target.page-1)*20
            })
            i++
        }
    },
    'rmb_trend': data=>{trend_data(data, display.trend_all, true)},
    'captain_total': i=>{
        display.data_view_captain.price = number_data(i['rst'], 0, true, 0.001)
        display.data_view_captain.loading = false
    },
    'captain_num': data=>{
        let target = display.data_view_captain.new_captain
        if (data['rst']==null) {
            target['1'] = '无数据'
            target['2'] = '无数据'
            target['3'] = '无数据'
        }else{
            target['1'] = 0
            target['2'] = 0
            target['3'] = 0
            for (let d of data['rst']){
                target[String(d['captain'])] = d['count']
            }
        }
    },
    'captain_all_list': data=>{
        let target = display.data_view_captain.viewer_list
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            target.total = 0
            return
        }
        target.total = data['num']
        for (let u of data['rst']){
            let captain_type = '舰长'
            if (u['captain']==2) captain_type = '提督'
            else if (u['captain']==1) captain_type = '总督'
            target.items.push({
                user: parse_user(u),
                time: parse_time(u['timestamp']),
                type: captain_type,
                num: u['captain_num'],
                total_price: number_data(u['captain_total_price'], 0, true, 0.001)
            })
        }
    },
    'superchat_total': i=>{
        display.data_view_superchat.price.all = number_data(i['rst'])
        display.data_view_superchat.loading = false
    },
    'superchat_other': i=>{display.data_view_superchat.price.other = number_data(i['rst'])},
    'superchat_viewer': data=>{
        let target = display.data_view_superchat.viewer_list
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            target.total = 0
            return
        }
        target.total = data['num']
        var i = 1
        for (let u of data['rst']){
            target.items.push({
                user: parse_user(u),
                total_price: number_format(u['sum']),
                rank: i+(target.page-1)*20
            })
            i++
        }
    },
    'superchat_all_list': data=>{
        let target = display.data_view_superchat.sc_list
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            target.total = 0
            return
        }
        target.total = data['num']
        for (let u of data['rst']){
            target.items.push({
                user: parse_user(u),
                time: parse_time(u['timestamp']),
                price: number_format(u['superchat_price']),
                content: u['superchat_content']
            })
        }
    },
    'superchat_search': data=>{
        let target = display.search_superchat
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            target.total = 0
            return
        }
        target.total = data['num']
        for (let u of data['rst']){
            target.items.push({
                user: parse_user(u),
                time: parse_time(u['timestamp']),
                content: u['superchat_content'],
                price: u['superchat_price']
            })
        }
    },
    'gold_coin_gift_total': i=>{
        display.data_view_gold_coin.gold.all = number_data(i['rst'], 2, true, 0.001)
        display.data_view_gold_coin.loading = false
    },
    'gold_coin_gift_other': i=>{display.data_view_gold_coin.gold.other = number_data(i['rst'], 2, true, 0.001)},
    'gold_coin_gift_viewer': data=>{
        let target = display.data_view_gold_coin.viewer_list
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            target.total = 0
            return
        }
        target.total = data['num']
        var i = 1
        for (let u of data['rst']){
            target.items.push({
                user: parse_user(u),
                total_price: number_data(u['sum'], 2, true, 0.001),
                rank: i+(target.page-1)*20
            })
            i++
        }
    },
    'gold_coin_gift_gift': data=>{
        let target = display.data_view_gold_coin.gift_list
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            return
        }
        let i = 1
        for (let u of data['rst']){
            target.items.push({
                gift_name: u['gift_name'],
                num: number_data(u['count']),
                total_price: number_data(u['sum'], 2, true, 0.001),
                rank: i
            })
            i++
        }
    },
    'silver_coin_gift_total': i=>{
        display.data_view_silver_coin.silver.all = number_data(i['rst'])
        display.data_view_silver_coin.loading = false
    },
    'silver_coin_gift_other': i=>{display.data_view_silver_coin.silver.other = number_data(i['rst'])},
    'silver_coin_gift_viewer': data=>{
        let target = display.data_view_silver_coin.viewer_list
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            target.total = 0
            return
        }
        target.total = data['num']
        var i = 1
        for (let u of data['rst']){
            target.items.push({
                user: parse_user(u),
                total_price: number_format(u['sum']),
                rank: i+(target.page-1)*20
            })
            i++
        }
    },
    'silver_coin_gift_gift': data=>{
        let target = display.data_view_silver_coin.gift_list
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            return
        }
        let i = 1
        for (let u of data['rst']){
            target.items.push({
                gift_name: u['gift_name'],
                num: number_format(u['count']),
                total_price: number_format(u['sum']),
                rank: i
            })
            i++
        }
    },
    'comment_total': i=>{
        display.data_view_comment.num.all = number_data(i['rst'])
        display.data_view_comment.loading = false
    },
    'comment_captain': i=>{display.data_view_comment.num.captain = number_data(i['rst'])},
    'comment_gachi': i=>{display.data_view_comment.num.gachi = number_data(i['rst'])},
    'comment_viewer': data=>{
        let target = display.data_view_comment.viewer_list
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            target.total = 0
            return
        }
        target.total = data['num']
        var i = 1
        for (let u of data['rst']){
            target.items.push({
                user: parse_user(u),
                num: number_format(u['sum']),
                rank: i+(target.page-1)*20
            })
            i++
        }
    },
    'comment_search': data=>{
        let target = display.search_comment
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            target.total = 0
            return
        }
        target.total = data['num']
        for (let u of data['rst']){
            target.items.push({
                user: parse_user(u),
                time: parse_time(u['timestamp']),
                content: u['content']
            })
        }
    },
    'comment_trend': data=>{trend_data(data, display.trend_all, true)},
    'user_search_name': data=>{
        let target = display.search_user
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            target.total = 0
            return
        }
        target.total = data['num']
        // 按数据长度排序
        data['rst'].sort((a,b)=>{
            return a['username'].length - b['username'].length
        })
        for (let u of data['rst']){
            target.items.push({
                user: {
                    uid: u['uid'],
                    name: u['username'],
                    medal_name: null,
                    medal_level: null,
                    medal_captain: 0,
                }
            })
        }
    },
    'user_comment_num': i=>{
        display.user_view_comment.num.all = number_data(i['rst'])
        display.user_view_comment.loading = false
    },
    'user_comment_list': data=>{
        let target = display.user_view_comment.comment_list
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            target.total = 0
            return
        }
        target.total = data['num']
        for (let u of data['rst']){
            target.items.push({
                time: parse_time(u['timestamp']),
                content: u['content']
            })
        }
    },
    'user_comment_trend': data=>{trend_data(data, display.trend_user)},
    'user_rmb_total': i=>{
        display.user_view_gift.num.all = number_data(i['rst'], 2, true, 0.001)
        display.user_view_gift.loading = false
    },
    'user_rmb_trend': data=>{trend_data(data, display.trend_user)},
    'user_gold_coin_gift_total': i=>{display.user_view_gift.num.gold = number_data(i['rst'], 2, true, 0.001)},
    'user_silver_coin_gift_total': i=>{display.user_view_gift.num.silver = number_data(i['rst'])},
    'user_silver_coin_gift_trend': data=>{trend_data(data, display.trend_user)},
    'user_gold_coin_gift_list': data=>{
        let target = display.user_view_gift.gold_list
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            target.total = 0
            return
        }
        target.total = data['num']
        for (let u of data['rst']){
            target.items.push({
                name: u["gift_name"],
                num: u["gift_num"],
                price: number_data(u["gift_total_price"], 2, true, 0.001),
                time: parse_time(u['timestamp'])
            })
        }
    },
    'user_silver_coin_gift_list': data=>{
        let target = display.user_view_gift.silver_list
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            target.total = 0
            return
        }
        target.total = data['num']
        for (let u of data['rst']){
            target.items.push({
                name: u["gift_name"],
                num: u["gift_num"],
                price: u["gift_total_price"],
                time: parse_time(u['timestamp'])
            })
        }
    },
    'user_superchat_num': i=>{
        display.user_view_superchat.num.num = number_data(i['rst'])
        display.user_view_superchat.loading = false
    },
    'user_superchat_total': i=>{display.user_view_superchat.num.price = number_data(i['rst'])},
    'user_superchat_list': data=>{
        let target = display.user_view_superchat.sc_list
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            target.total = 0
            return
        }
        target.total = data['num']
        for (let u of data['rst']){
            target.items.push({
                content: u["superchat_content"],
                price: u["superchat_price"],
                time: parse_time(u['timestamp'])
            })
        }
    },
    'user_captain_total': i=>{
        display.user_view_captain.price = number_data(i['rst'], 0, true, 0.001)
        display.user_view_captain.loading = false
    },
    'user_captain_num': data=>{
        let target = display.user_view_captain.new_captain
        if (data['rst']==null) {
            target['1'] = '无数据'
            target['2'] = '无数据'
            target['3'] = '无数据'
        }else{
            target['1'] = 0
            target['2'] = 0
            target['3'] = 0
            for (let d of data['rst']){
                target[String(d['captain'])] = d['count']
            }
        }
    },
    'user_captain_list': data=>{
        let target = display.user_view_captain.captain_list
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            target.total = 0
            return
        }
        target.total = data['num']
        for (let u of data['rst']){
            let captain_type = '舰长'
            if (u['captain']==2) captain_type = '提督'
            else if (u['captain']==1) captain_type = '总督'
            target.items.push({
                time: parse_time(u['timestamp']),
                type: captain_type,
                num: u['captain_num'],
                total_price: number_data(u['captain_total_price'], 0, true, 0.001)
            })
        }
    },
    'user_interact_trend': data=>{trend_data(data, display.trend_user)},
    'user_former_name': data=>{
        let target = display.user_view_former.name_list
        display.user_picker.loading = false
        target.loading = false
        target.items = []
        if (data['rst']==null) {
            //设置info
            target = window.data.user_view_info
            target.loading = false
            target.username = '当前时间段未找到该用户信息'
            target.uid = '未找到'
            return
        }
        for (let u of data['rst']){
            target.items.push({
                name: u["username"],
                time: parse_time(u['timestamp'])
            })
        }
        //设置info
        target = window.data.user_view_info
        target.loading = false
        target.uid = window.data.user_picker.preserved.uid
    },
    'user_former_tag': data=>{
        let target = display.user_view_former.medal_list
        target.loading = false
        target.items = []
        if (data['rst']==null) return
        for (let u of data['rst']){
            let captain_type = '--'
            if (u['captain']==3) captain_type = '舰长'
            else if (u['captain']==2) captain_type = '提督'
            else if (u['captain']==1) captain_type = '总督'
            target.items.push({
                time: parse_time(u['timestamp']),
                captain: captain_type,
                name: u['medal_name'],
                level: u['medal_level']
            })
        }
    },
}

export default {parse}