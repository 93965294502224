<template>
<div>
  <div class="m-0 p-3 p-md-5 bg-white row">
    <b-col md="12" class="py-3"><h1>醒目留言查找</h1></b-col>
    <vup-picker class="col-md-3 py-3" />
    <b-col md="4" class="py-3">
      <h5 class="pb-2">关键词：</h5>
      <b-input-group prepend="关键词">
        <b-form-input placeholder="醒目留言包含的关键词" v-model="keyword"></b-form-input>
      </b-input-group>
    </b-col>
    <date-picker class="col-md-5 py-3" />
    <div class="col py-3">
      <b-button block variant="primary" :disabled="loading && init" @click="get_data()">{{search_text}}</b-button>
    </div>
  </div>
  <div class="m-3 text-secondary row" v-if="init">
    <b-col md="auto">数据来源：{{vup_text}}</b-col>
    <b-col md="auto">关键词：{{preserved.keyword}}</b-col>
    <b-col md="auto">日期范围：{{date_text}}</b-col>
  </div>
  <div class="p-4 p-md-5 m-3 bg-white" v-if="!loading">
    <h4 class="pb-3">查找结果：</h4>
    <div class="row">
      <div class="col text-secondary">共{{total}}条符合条件的醒目留言</div>
      <b-form-checkbox
        class="col text-right"
        v-model="preserved.desc"
        @change="change_time_order"
      >
        时间倒序排列
      </b-form-checkbox>
    </div>
    <table class="table table-sm table-bordered table-hover" id="search_superchat">
      <thead class="thead-light">
        <tr>
          <th>时间</th>
          <th>观众</th>
          <th>￥￥￥</th>
          <th>内容</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(sc, idx) in items" :key="sc.content+idx">
          <td>{{sc.time}}</td>
          <td><user-popover prefix="search_superchat" :id="idx" :user="sc.user"/></td>
          <td>￥{{sc.price}}</td>
          <td>{{sc.content}}</td>
        </tr>
      </tbody>
    </table>
    <b-pagination
      v-model="page"
      :total-rows="total"
      :per-page="50"
      first-number
      last-number
      align="center"
      v-on:change="change_page"
    ></b-pagination>
  </div>

</div>
</template>
<script>
import query from '@/query.js'
import date_picker from '@/components/Date-picker.vue'
import vup_picker from '@/components/Vup-picker.vue'
var dayjs = require('dayjs');
window.data.search_superchat = {
  loading: true,
  init: false,
  keyword: '',
  total: 0,
  page: 1,
  preserved: {
    vid: '',
    date_range: ['', ''],
    keyword: '',
    desc: false,
  },
  items: [
    {
      time: 'YYYY/MM/DD HH:mm:ss',
      price: 30,
      content: '还没加载出来呢',
      user: {
        uid: 198165375,
        name: '冰绒冷焰',
        medal_name: '雪狐咕',
        medal_level: 20,
        medal_captain: 3,
      }
    }
  ]
}
var data = window.data.search_superchat
export default {
  name: 'Search-superchat',
  components: {
    'date-picker': date_picker,
    'vup-picker': vup_picker,
  },
  data: ()=>{
    return data
  },
  methods: {
    get_data: function(){
      this.page = 1
      this.preserved.vid = window.data.vup_picker.vid
      this.preserved.date_range = query.cal_date_range()
      this.preserved.keyword = this.keyword
      query.query([{"method": "superchat_search", "keyword": this.keyword}], this.preserved)
      this.loading = true
      this.init = true
    },
    change_page: function(page){
      document.getElementById("search_superchat").scrollIntoView()
      query.query([{"method":"superchat_search", "page": page, "keyword": this.preserved.keyword}], this.preserved)
    },
    change_time_order: function(checked){
      this.page = 1
      this.preserved.desc = checked
      query.query([{"method":"superchat_search", "keyword": this.preserved.keyword}], this.preserved)
    }
  },
  computed: {
    search_text: ()=>{
      if (data.loading && data.init) return '查找中...'
      else return '查找'
    },
    vup_text: function(){
      var xuehu = 'xuehu';
      if (this.preserved.vid== xuehu) return '雪狐'
      return '等待中'
    },
    date_text: function(){
      var start = dayjs(this.preserved.date_range[0])
      var end = dayjs(this.preserved.date_range[1])
      return start.format('YYYY-MM-DD')+'至'+end.format('YYYY-MM-DD')
    }
  }
}
</script>