<template>
<div>
  <div class="m-0 p-3 p-md-5 bg-white row">
    <b-col md="12" class="py-3"><h1>观众查找</h1></b-col>
    <b-col md="12" class="m-3">只要这个观众在直播间发过弹幕、送过礼物，并且id里包含关键词，就会被筛选出来~</b-col> 
    <b-input-group prepend="观众名" class="mt-3 col-md-6">
      <b-form-input placeholder="你想查找的观众名" v-model="username"></b-form-input>
      <b-input-group-append>
        <b-button variant="primary" :disabled="loading && init" @click="get_data()">{{search_text}}</b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
  <div class="p-4 p-md-5 m-3 bg-white" v-if="!loading">
    <h4 class="pb-3">查询结果：</h4>
    <div>共{{total}}条查找结果</div> 
    <b-row>
      <b-col md="4" class="text-center py-2" v-for="(user, idx) in items.slice((page-1)*50, page*50)" :key="user.uid">
        <user-popover prefix="user-search" :id="idx" :user="user.user"/>
      </b-col>
    </b-row>
    <b-pagination v-model="page" :total-rows="total" per-page="50" align="center"></b-pagination>
  </div>

</div>
</template>
<script>
import query from '@/query.js'
window.data.search_user = {
  loading: true,
  init: false,
  username: '',
  total: 0,
  page: 1,
  items: [
    {
      user: {
        uid: 198165375,
        name: '冰绒冷焰',
        medal_name: '雪狐咕',
        medal_level: 20,
        medal_captain: 3,
      }
    }
  ]
}
var data = window.data.search_user
export default {
  name: 'Search-user',
  data: ()=>{
    return data
  },
  methods: {
    get_data: ()=>{
      if (data.username=='') return
      query.query(
        [{"method": "user_search_name", "keyword": data.username}],
        {
          vid: 'xuehu',
          date_range: query.cal_date_range(true), 
          desc: true,
        }
      )
      data.loading = true
      data.init = true
    }
  },
  computed: {
    search_text: ()=>{
      if (data.loading && data.init) return '查找中...'
      else return '查找'
    }
  }
}
</script>