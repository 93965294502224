<template>
    <b-card class="m-3" no-body>
      <b-card-header header-tag="nav">
        <b-nav card-header pills>
          <b-nav-item :active="tabs[0]" v-on:click="select(0)">醒目留言总量</b-nav-item>
          <b-nav-item :active="tabs[1]" v-on:click="select(1)">醒目留言列表</b-nav-item>
        </b-nav>
      </b-card-header>
      <b-card-body v-if="tabs[0]">
        <b-row>
          <div class="col-md-6 text-center my-2">
            <h5>醒目留言数量</h5>
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton width="100%"></b-skeleton>
                <b-skeleton width="80%"></b-skeleton>
              </template>
              <div>{{num.num}}</div>
            </b-skeleton-wrapper>
          </div>
          <div class="col-md-6 text-center my-2">
            <h5>醒目留言价值</h5>
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton width="100%"></b-skeleton>
                <b-skeleton width="80%"></b-skeleton>
              </template>
              <div>￥{{num.price}}</div>
            </b-skeleton-wrapper>
          </div>
        </b-row>
      </b-card-body>
      <b-card-body v-if="tabs[1]">
        <b-skeleton-wrapper :loading="sc_list.loading">
          <template #loading>
            <b-skeleton width="30%"></b-skeleton>
            <b-skeleton-table
              :rows="sc_list.items.length+1"
              :columns="2"
              :table-props="{bordered: true}"
              :small="true"
            ></b-skeleton-table>
          </template>
          <div class="row">
            <div class="col text-secondary">共{{sc_list.total}}条醒目留言</div>
            <b-form-checkbox
              class="col text-right"
              v-model="sc_list.desc"
              @change="change_time_order"
            >
              时间倒序排列
            </b-form-checkbox>
          </div>
          <table class="table table-sm table-bordered table-hover" id="user_view_superchat">
            <thead class="thead-light">
              <tr>
                <th>时间</th>
                <th>价值￥</th>
                <th>内容</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(sc, idx) in sc_list.items" :key="sc.content+idx">
                <td>{{sc.time}}</td>
                <td>￥{{sc.price}}</td>
                <td>{{sc.content}}</td>
              </tr>
            </tbody>
          </table>
          <b-pagination
            v-model="sc_list.page"
            :total-rows="sc_list.total"
            :per-page="20"
            first-number
            last-number
            align="center"
            v-on:change="change_page"
          ></b-pagination>
        </b-skeleton-wrapper>
      </b-card-body>
    </b-card>
</template>

<script>
window.data.user_view_superchat = {
  tabs: [true, false],
  loading: true,
  num: {
    num: 0,
    price: 0
  },
  sc_list: {
    page: 1,
    total: 1,
    init: false,
    desc: false,
    loading: true,
    items: [
      {
        time: 'YYYY/MM/DD HH:mm:ss',
        price: 0,
        content: '还没加载出来呢',
      }
    ]
  }
}
var data = window.data.user_view_superchat
var query_params = window.data.user_picker.preserved
import query from '@/query.js'
export default {
  name: 'user-view-superchat',
  data: ()=>{
    return data
  },
  methods: {
    select: (x)=>{
      if (x == 1 && data.sc_list.init == false){
        query_params.desc = data.sc_list.desc
        query.query([{"method":"user_superchat_list"}], query_params)
        data.sc_list.init = true
        data.sc_list.loading = true
      }
      var tabs = [false, false]
      tabs[x] = true
      data.tabs = tabs
    },
    change_page: function(page){
      document.getElementById("user_view_superchat").scrollIntoView()
        query_params.desc = data.sc_list.desc
      query.query([{"method":"user_superchat_list", "page": page}], query_params)
    },
    change_time_order: checked=>{
      data.sc_list.page = 1
        query_params.desc = checked
      query.query([{"method":"user_superchat_list", "page": data.sc_list.page}], query_params)
    }
  }
}
</script>
