<template>
  <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core"
import { CanvasRenderer } from "echarts/renderers"
import { LineChart } from "echarts/charts"
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
  DatasetComponent
} from "echarts/components"
use([
  CanvasRenderer,
  LineChart,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  DatasetComponent
])

import VChart, { THEME_KEY } from "vue-echarts";
export default {
  name: "chart-line",
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  props: ['chartdata', 'name'],
  data() {
    return {
      option: {
        grid:{
          show: true,
          containLabel: true,
          left: '3%',
          right: '4%',
          bottom: '3%',
        },
        title: {
          text: this.name,
          left: "center"
        },
        tooltip: {
          trigger: 'axis'
        },
        dataset: {
          source: this.chartdata
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: true
          },
          axisTick: {
            show: true
          },
        },
        series: [{
          type: 'line',
        }]
      }
    };
  },
  watch: {
    chartdata(){
      this.option.dataset.source = this.chartdata
    },
    name(){
      this.option.title.text = this.name
    },
  }
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>