import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import './plugins/bootstrap-vue'
import App from './App.vue'
import Home from './views/Home.vue'
import Data from './views/Data.vue'
import User from './views/User.vue'
import Search from './views/Search.vue'
import SearchUser from './views/Search/user.vue'
import SearchComment from './views/Search/comment.vue'
import SearchSuperchat from './views/Search/superchat.vue'
import Trend from './views/Trend.vue'
import TrendAll from './views/Trend/all.vue'
import TrendUser from './views/Trend/user.vue'
import Info from './views/Info.vue'
import user_popover from './components/User-popover.vue'

Vue.use(VueRouter)
Vue.use(VueCookies)
Vue.config.productionTip = false
Vue.$cookies.config('90d')

const router = new VueRouter({
  routes: [
    {
      path: '/',
      component: Home
    },
    {
      path: '/data',
      component: Data
    },
    {
      path: '/user',
      component: User
    },
    {
      path: '/search',
      component: Search,
      children: [
        { path: '', redirect: 'user'},
        { path: 'user', component: SearchUser},
        { path: 'comment', component: SearchComment},
        { path: 'superchat', component: SearchSuperchat}
      ]
    },
    {
      path: '/trend',
      component: Trend,
      children: [
        { path: '', redirect: 'all'},
        { path: 'all', component: TrendAll},
        { path: 'user', component: TrendUser},
      ]
    },
    {
      path: '/info',
      component: Info
    },
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})


Vue.component('user-popover', user_popover)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
