<template>
<div>
  <div class="m-0 p-3 p-md-5 bg-white row">
    <b-col md="12" class="py-3"><h1>趋势——</h1></b-col>
    <vup-picker exclude_all class="col-md-3 py-3" />
    <b-col md="4" class="py-3">
      <h5 class="pb-2">选择类别：</h5>
      <b-form-select v-model="selected" :options="options"></b-form-select>
    </b-col>
    <date-picker exclude_day class="col-md-5 py-3" />
    <div class="col py-3">
      <b-button block variant="primary" :disabled="loading && init" @click="get_data()">{{search_text}}</b-button>
    </div>
  </div>
  <div class="m-3 text-secondary row" v-if="init">
    <b-col md="auto">数据来源：{{vup_text}}</b-col>
    <b-col md="auto">类别：{{chart_name}}</b-col>
    <b-col md="auto">日期范围：{{date_text}}</b-col>
  </div>
  <div class="p-4 p-md-5 m-3 bg-white" v-if="init&&(!loading)">
    <chart-line :chartdata="chartdata" :name="chart_name"/>
  </div>
</div>
</template>


<script>
import query from '@/query.js'
import date_picker from '@/components/Date-picker.vue'
import vup_picker from '@/components/Vup-picker.vue'
import chart_line from '@/components/Chart-line.vue'
var dayjs = require('dayjs');
window.data.trend_all = {
  loading: true,
  init: false,
  selected: 'comment_trend',
  options: [
    {'value':'comment_trend', 'text':'弹幕量'},
    {'value':'rmb_trend', 'text':'总收入￥'},
    {'value':'interact_trend', 'text':'互动人数'},
    {'value':'view_trend', 'text':'人气'}
  ],
  chartdata: [],
  preserved: {
    selected: '',
    date_range: ['', ''],
    vid: ''
  }
}
var data = window.data.trend_all
export default {
  name: 'Trend-all',
  components: {
    'date-picker': date_picker,
    'vup-picker': vup_picker,
    'chart-line': chart_line,
  },
  data: ()=>{
    return data
  },
  methods: {
    get_data: function(){
      this.preserved.vid = window.data.vup_picker.vid
      this.preserved.date_range = query.cal_date_range()
      this.preserved.selected = this.selected
      query.query([{"method": this.selected}], {
        vid: window.data.vup_picker.vid, 
        date_range: window.data.date_picker.date_range, 
        desc: false,
        live: 'on_live'
      })
      this.init = true
      this.loading = true
    }
  },
  computed: {
    chart_name: function(){
      for (let i of this.options)
        if (this.preserved.selected==i['value'])
          return i['text']
      return ''
    },
    search_text: function(){
      if (this.loading && this.init) return '获取中...'
      else return '查看趋势！'
    },
    vup_text: function(){
      if (this.preserved.vid=='xuehu') return '雪狐'
      return '等待...'
    },
    date_text: function(){
      var start = dayjs(this.preserved.date_range[0])
      var end = dayjs(this.preserved.date_range[1])
      return start.format('YYYY-MM-DD')+'至'+end.format('YYYY-MM-DD')
    }
  }
}
</script>