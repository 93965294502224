<template>
    <b-card class="m-3" no-body>
      <b-card-header header-tag="nav">
        <b-nav card-header pills>
          <b-nav-item :active="tabs[0]" v-on:click="select(0)">舰团收入</b-nav-item>
          <b-nav-item :active="tabs[1]" v-on:click="select(1)">新增舰团列表</b-nav-item>
        </b-nav>
      </b-card-header>
      <b-card-body v-if="tabs[0]">
        <b-row>
          <div class="col-md-6 text-center my-2">
            <h5>舰团收入</h5>
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton width="100%"></b-skeleton>
                <b-skeleton width="80%"></b-skeleton>
              </template>
              <div>￥{{price}}</div>
            </b-skeleton-wrapper>
          </div>
          <div class="col-md-6 text-center my-2">
            <h5>新增舰团数量（包含续舰）</h5>
            <b-skeleton-wrapper :loading="loading">
              <template #loading>
                <b-skeleton width="100%"></b-skeleton>
                <b-skeleton width="80%"></b-skeleton>
              </template>
              <div>总督: {{new_captain['1']}}</div>
              <div>提督: {{new_captain['2']}}</div>
              <div>舰长: {{new_captain['3']}}</div>
            </b-skeleton-wrapper>
          </div>
        </b-row>
      </b-card-body>
      <b-card-body v-if="tabs[1]">
        <b-skeleton-wrapper :loading="viewer_list.loading">
          <template #loading>
            <b-skeleton width="30%"></b-skeleton>
            <b-skeleton-table
              :rows="viewer_list.items.length+1"
              :columns="5"
              :table-props="{bordered: true}"
              :small="true"
            ></b-skeleton-table>
          </template>
          <div class="row">
            <div class="col-auto text-secondary">共{{viewer_list.total}}人次上舰</div>
            <b-form-radio-group
              class="col text-center"
              v-model="viewer_list.cpt_type"
              :options="viewer_list.type_options"
              @change="change_cpt_type"
            ></b-form-radio-group>
            <b-form-checkbox
              class="col-auto text-right"
              v-model="viewer_list.desc"
              @change="change_time_order"
            >
              时间倒序排列
            </b-form-checkbox>
          </div>
          <table class="table table-sm table-bordered table-hover" id="data_view_captain">
            <thead class="thead-light">
              <tr>
                <th>时间</th>
                <th>观众</th>
                <th>跳转</th>
                <th>类别</th>
                <th>数量（几个月）</th>
                <th>￥￥￥</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(captain, index) in viewer_list.items" :key="captain.rank">
                <td>{{captain.time}}</td>
                <td><user-popover prefix="captain" :id="index" :user="captain.user"/></td>
                <td><a :href="'https://message.bilibili.com/#/whisper/mid'+captain.user.uid" target="__blank">前往私信</a></td>
                <td>{{captain.type}}</td>
                <td>{{captain.num}}</td>
                <td>￥{{captain.total_price}}</td>
              </tr>
            </tbody>
          </table>
          <b-pagination
            v-model="viewer_list.page"
            :total-rows="viewer_list.total"
            :per-page="20"
            first-number
            last-number
            align="center"
            @change="change_page"
          ></b-pagination>
        </b-skeleton-wrapper>
      </b-card-body>
    </b-card>
</template>

<script>
window.data.data_view_captain = {
  tabs: [true, false],
  price: 0,
  loading: true,
  new_captain: {
    '1': 0,
    '2': 0,
    '3': 0
  },
  viewer_list: {
    page: 1,
    total: 1,
    init: false,
    loading: true,
    cpt_type: 0,
    type_options: [
      {text: '全部', value: 0},
      {text: '舰长', value: 3},
      {text: '提督', value: 2},
      {text: '总督', value: 1},
    ],
    items: [
      {
        time: '00:00:00',
        type: '舰长',
        num: 1,
        total_price: 0,
        user: {
          uid: 198165375,
          name: '冰绒冷焰',
          medal_name: '雪狐咕',
          medal_level: 20,
          medal_captain: 0,
        }
      }
    ]
  }
}
var data = window.data.data_view_captain
var query_params = window.data.data_picker.preserved
import query from '@/query.js'
export default {
  name: 'data-view-captain',
  data: ()=>{
    return data
  },
  methods: {
    select: (x)=>{
      if (x == 1 && data.viewer_list.init == false){
        query_params.desc = data.viewer_list.desc
        query.query([{"method":"captain_all_list"}], query_params)
        data.viewer_list.init = true
        data.viewer_list.loading = true
      }
      var tabs = [false, false]
      tabs[x] = true
      data.tabs = tabs
    },
    change_page: page=>{
      document.getElementById("data_view_captain").scrollIntoView()
      query_params.desc = data.viewer_list.desc
      query.query([{"method":"captain_all_list", "page": page}], query_params)
    },
    change_time_order: checked=>{
      data.viewer_list.page = 1
      query_params.desc = checked
      query.query([{"method":"captain_all_list", "page": data.viewer_list.page}], query_params)
    },
    change_cpt_type: ()=>{
      data.viewer_list.page = 1
      query_params.desc = data.viewer_list.desc
      query.query([{"method":"captain_all_list", "page": data.viewer_list.page}], query_params)
    },
    go_message: function (){

    }
  }
}
</script>

