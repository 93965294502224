<template>
<div>
  <div class="m-0 p-3 p-md-5 bg-white row">
    <b-col md="12" class="py-3"><h1>趋势——</h1></b-col>
    <vup-picker exclude_all class="col-md-3 py-3" />
    <b-col md="4" class="py-3">
      <h5>观众：</h5>
      <b-input-group class="mt-1">
        <template #prepend>
          <b-form-select class="rounded-0" v-model="user_selected" :options="user_options" @change="change_cat"></b-form-select>
        </template>
        <b-form-input v-model="keyword"></b-form-input>
      </b-input-group>
      <h5 class="mt-3">选择类别：</h5>
      <b-form-select v-model="selected" :options="options"></b-form-select>
    </b-col>
    <date-picker exclude_day class="col-md-5 py-3" />
    <div class="col py-3">
      <b-button block variant="primary" :disabled="loading && init" @click="search">{{search_text}}</b-button>
    </div>
  </div>
  <div class="m-3 text-secondary row" v-if="init">
    <b-col md="auto">数据来源：{{vup_text}}</b-col>
    <b-col md="auto">观众UID：{{preserved.uid}}</b-col>
    <b-col md="auto">观众现用名：{{username}}</b-col>
    <b-col md="auto">类别：{{chart_name}}</b-col>
    <b-col md="auto">日期范围：{{date_text}}</b-col>
  </div>
  <div class="p-4 p-md-5 m-3 bg-white" v-if="init&&(!loading)">
    <chart-line :chartdata="chartdata" :name="chart_name"/>
  </div>
</div>
</template>


<script>
import query from '@/query.js'
import date_picker from '@/components/Date-picker.vue'
import vup_picker from '@/components/Vup-picker.vue'
import chart_line from '@/components/Chart-line.vue'
var dayjs = require('dayjs');
window.data.trend_user = {
  loading: true,
  init: false,
  user_selected: 'username',
  username: '...',
  user_options: [
    { text: 'UID', value: 'uid' },
    { text: '用户名', value: 'username' }
  ],
  keyword: '',
  selected: 'user_comment_trend',
  options: [
    {'value':'user_comment_trend', 'text':'弹幕量'},
    {'value':'user_rmb_trend', 'text':'总投喂￥'},
    {'value':'user_silver_coin_gift_trend', 'text':'银瓜子礼物数量'},
    {'value':'user_interact_trend', 'text':'互动量（弹幕+送礼）'},
  ],
  chartdata: [],
  preserved: {
    selected: '',
    date_range: ['', ''],
    vid: '',
    uid: '',
    username: ''
  }
}
var data = window.data.trend_user
export default {
  name: 'Trend-all',
  components: {
    'date-picker': date_picker,
    'vup-picker': vup_picker,
    'chart-line': chart_line,
  },
  data: ()=>{
    return data
  },
  methods: {
    search: function(){
      if (this.keyword=='') return
      // 初始化
      this.init = true
      this.loading = true
      // 是否找uid
      if (this.user_selected=='uid'){
        this.preserved.uid = this.keyword
        this.get_data()
      }
      else{
        // 查找用户
        this.preserved.username = this.keyword
        query.query(
          [{"method":"user_search_name_acc", "keyword":this.preserved.username}],
          {
            vid: "xuehu",
            date_range: query.cal_date_range(true)
          },
          this.username_callback
        )
      }
    },
    username_callback: function(d){
      if (d[0]['rst']==null || d[0]['rst'].length==0){
        this.loading = false
        alert('找不到对应的用户！或许可以到搜索视图找找看？')
        return
      }
      this.preserved.uid = d[0]['rst'][0]['uid']
      this.get_data()
    },
    get_data: function(){
      this.preserved.vid = window.data.vup_picker.vid
      this.preserved.date_range = query.cal_date_range()
      this.preserved.selected = this.selected
      query.query([{"method": this.selected, "uid": parseInt(this.preserved.uid)}], this.preserved)
      // 请求观众现用名
      query.query(
        [{"method": "user_current_name", "uid": parseInt(this.preserved.uid)}],
        {
          vid: this.preserved.vid, 
          date_range: query.cal_date_range(true), 
          desc: true,
        },
        this.current_name_callback
      )
    },
    current_name_callback: function(d){
      this.username = d[0]['rst'][0]["username"]
    },
    change_cat: function(){
      this.keyword = ''
    }
  },
  computed: {
    chart_name: function(){
      for (let i of this.options)
        if (this.preserved.selected==i['value'])
          return i['text']
      return ''
    },
    search_text: function(){
      if (this.loading && this.init) return '获取中...'
      else return '查看趋势！'
    },
    vup_text: function(){
      var xuehu = 'xuehu';
      if (this.preserved.vid== xuehu) return '雪狐桑'
      return '等待中'
    },
    date_text: function(){
      var start = dayjs(this.preserved.date_range[0])
      var end = dayjs(this.preserved.date_range[1])
      return start.format('YYYY-MM-DD')+'至'+end.format('YYYY-MM-DD')
    }
  },
  mounted(){
    if (this.$route.query['s'] == 'true'){
      this.search()
    }
  }
}
</script>