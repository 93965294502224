<template>
  <div>
    <b-link :id="`popover-${pid}`" class="pr-4" @click="jump()">{{ user.name }}</b-link>
    <b-popover
      :target="`popover-${pid}`"
      title="观众信息"
      triggers="hover focus"
    >
      <div class="row">
        <div class="col">{{captain}}</div>
        <div class="col-auto">
          <div class="row border border-primary rounded m-0 p-0">
            <div class="col-auto bg-primary text-white p-0 px-1" v-if="user.medal_name!=null">{{user.medal_name}}</div>
            <div class="col p-0 px-1" v-if="user.medal_name!=null">{{user.medal_level}}</div>
            <div class="col" v-if="user.medal_name==null && user.medal_captain==null">无牌子</div>
            <div class="col" v-if="user.medal_name==null && user.medal_captain!=null">牌子未知</div>
          </div>
        </div>
      </div>
      <div class="text-secondary pt-3">UID: {{user.uid}}</div>
      <div class="text-secondary">点击查看观众详细信息</div>
    </b-popover>
  </div>
</template>

<script>
export default {
  name: 'data-view-gold-coin',
  props: {
    prefix: String,
    id: Number,
    user: {
      type: Object,
      default: ()=>{
        return {
          'uid': 198165375,
          'name': '冰绒冷焰',
          'medal_name': '雪狐咕',
          'medal_level': 20,
          'medal_captain': 3
        }
      }
    }
  },
  data: function(){
    var cap = ''
    if (this.user.medal_captain == 3) cap = '舰长'
    else if (this.user.medal_captain == 2) cap = '提督'
    else if (this.user.medal_captain == 1) cap = '总督'
    return {
      pid: this.prefix+"-"+this.id,
      captain: cap
    }
  },
  methods: {
    jump: function(){
      window.data.user_picker.selected = 'uid'
      window.data.user_picker.keyword = this.user.uid
      window.data.user_picker.preserved.uid = this.user.uid
      this.$router.push('/user?s=true')
    }
  }
}
</script>