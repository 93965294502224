<template>
  <div id="app">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand to="/">冰山一角</b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item active-class="active" to="/data">数据总览</b-nav-item>
          <b-nav-item active-class="active" to="/search">搜索</b-nav-item>
          <b-nav-item active-class="active" to="/trend">趋势</b-nav-item>
          <b-nav-item active-class="active" to="/user">观众</b-nav-item>
          <b-nav-item active-class="active" to="/info">关于</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view/>
  </div>
</template>

<script>
window.data = {}
export default {
  name: 'App'
}
</script>
