<template>
  <div>
    <b-nav tabs align="center">
      <b-nav-item active-class="active" to="/search/user">观众查找</b-nav-item>
      <b-nav-item active-class="active" to="/search/comment">弹幕搜索</b-nav-item>
      <b-nav-item active-class="active" to="/search/superchat">醒目留言搜索</b-nav-item>
    </b-nav>
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: 'Search',
  components: {
  }
}
</script>