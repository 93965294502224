<template>
<div>
  <div class="p-4 p-md-5 m-3 bg-white row">
    <b-col md="12" class="py-3"><h1>观众信息</h1></b-col>
    <vup-picker class="col-md-3 py-3" />
    <div class="col-md-4 py-3">
      <h5 class="pb-2">观众：</h5>
      <b-input-group>
        <template #prepend>
          <b-form-select class="rounded-0" v-model="selected" :options="options" @change="change_cat"></b-form-select>
        </template>
        <b-form-input v-model="keyword"></b-form-input>
      </b-input-group>
    </div>
    <date-picker class="col-md-5 py-3" />
    <div class="col py-3">
      <b-button block variant="primary" :disabled="loading && init" @click="search()">{{search_text}}</b-button>
    </div>
  </div>
  <div class="m-3 text-secondary row" v-if="init">
    <b-col md="auto">数据来源：{{vup_text}}</b-col>
    <b-col md="auto">观众uid：{{preserved.uid}}</b-col>
    <b-col md="auto">日期范围：{{date_text}}</b-col>
  </div>
</div>  
</template>

<script>
import date_picker from '@/components/Date-picker.vue'
import vup_picker from '@/components/Vup-picker.vue'
import query from '@/query.js'
var dayjs = require('dayjs');
window.data.user_picker = {
  selected: 'username', 
  options: [
    { text: 'UID', value: 'uid' },
    { text: '用户名', value: 'username' }
  ],
  keyword: '',
  init: false,
  loading: true,
  preserved: {
    vid: '',
    date_range: ['', ''],
    username: '',
    uid: '',
    desc: false
  }
}
var data = window.data.user_picker
export default {
  name: 'user-picker',
  components: {
    'date-picker': date_picker,
    'vup-picker': vup_picker,
  },
  data: function() {
    return data
  },
  methods: {
    search: function(){
      if (this.keyword=='') return
      // 初始化
      this.init_list()
      // 是否找uid
      if (this.selected=='uid'){
        this.preserved.uid = this.keyword
        this.get_data()
      }
      else{
        // 查找用户
        this.preserved.username = this.keyword
        query.query(
          [{"method":"user_search_name_acc", "keyword":this.preserved.username}],
          {
            vid: "xuehu",
            date_range: query.cal_date_range(true)
          },
          this.username_callback
        )
      }
    },
    username_callback: function(d){
      if (d[0]['rst']==null || d[0]['rst'].length==0){
        this.loading = false
        alert('找不到对应的用户！或许可以到搜索视图找找看？')
        return
      }
      this.preserved.uid = d[0]['rst'][0]['uid']
      this.get_data()
    },
    get_data: function(){
      // 保存请求参数
      this.preserved.vid = window.data.vup_picker.vid
      this.preserved.date_range = query.cal_date_range()

      // 创建请求
      // 基础列表
      let qlist = [
          {"method":"user_comment_num"},
          {"method":"user_rmb_total"},
          {"method":"user_gold_coin_gift_total"},
          {"method":"user_silver_coin_gift_total"},
          {"method":"user_captain_total"},
          {"method":"user_captain_num"},
          {"method":"user_superchat_num"},
          {"method":"user_superchat_total"}
      ]
      // 附加请求（根据展开的列表判断）
      let display = window.data
      if(display.user_view_comment.tabs[1]==true) qlist.push({"method": "user_comment_list"})
      if(display.user_view_captain.tabs[1]==true) qlist.push({"method": "user_captain_list"})
      if(display.user_view_gift.tabs[1]==true) qlist.push({"method": "user_gold_coin_gift_list"})
      if(display.user_view_gift.tabs[2]==true) qlist.push({"method": "user_silver_coin_gift_list"})
      if(display.user_view_superchat.tabs[1]==true) qlist.push({"method": "user_superchat_list"})
      // 请求
      query.query(qlist, this.preserved)
      // 请求观众现用名
      query.query(
        [{"method": "user_current_name"}],
        {
          vid: this.preserved.vid, 
          date_range: query.cal_date_range(true), 
          desc: true,
        },
        this.current_name_callback
      )
      // 请求曾用名和tag
      query.query([
        {"method":"user_former_name"},
        {"method":"user_former_tag"}
      ], this.preserved)
    },
    current_name_callback: function(d){
      window.data.user_view_info.username = d[0]['rst'][0]["username"]
    },
    init_list: function(){
      let display = window.data
      // 初始化列表
      display.user_view_comment.comment_list.init = display.user_view_comment.tabs[1]
      display.user_view_gift.gold_list.init = display.user_view_gift.tabs[1]
      display.user_view_gift.silver_list.init = display.user_view_gift.tabs[2]
      display.user_view_captain.captain_list.init = display.user_view_captain.tabs[1]
      display.user_view_superchat.sc_list.init = display.user_view_superchat.tabs[1]
      display.user_view_former.name_list.init = display.user_view_former.tabs[1]
      display.user_view_former.medal_list.init = display.user_view_former.tabs[2]
      display.user_picker.init = true
      // 所有列表回到第一页
      display.user_view_comment.comment_list.page = 1
      display.user_view_gift.gold_list.page = 1
      display.user_view_gift.silver_list.page = 1
      display.user_view_captain.captain_list.page = 1
      display.user_view_superchat.sc_list.page = 1
      display.user_view_former.name_list.page = 1
      display.user_view_former.medal_list.page = 1
      // 所有列表时间倒序
      display.user_view_comment.comment_list.desc = false
      display.user_view_gift.gold_list.desc = false
      display.user_view_gift.silver_list.desc = false
      display.user_view_captain.captain_list.desc = false
      display.user_view_superchat.sc_list.desc = false
      display.user_picker.preserved.desc = false
      // 所有项目loading
      display.user_view_info.loading = true
      display.user_view_comment.loading = true
      display.user_view_comment.comment_list.loading = true
      display.user_view_gift.loading = true
      display.user_view_gift.gold_list.loading = true
      display.user_view_gift.silver_list.loading = true
      display.user_view_captain.loading = true
      display.user_view_captain.captain_list.loading = true
      display.user_view_superchat.loading = true
      display.user_view_superchat.sc_list.loading = true
      display.user_view_former.name_list.loading = true
      display.user_view_former.medal_list.loading = true
      display.user_picker.loading = true
    },
    change_cat: function(){
      this.keyword = ''
    }
  },
  computed: {
    search_text: function(){
      if (this.loading && this.init) return '获取中...'
      else return '获取数据！'
    },
    vup_text: function(){
      var xuehu = 'xuehu';
      if (this.preserved.vid== xuehu) return '雪狐'
      return '等待中'
    },
    date_text: function(){
      var start = dayjs(this.preserved.date_range[0])
      var end = dayjs(this.preserved.date_range[1])
      return start.format('YYYY-MM-DD')+'至'+end.format('YYYY-MM-DD')
    }
  },
  mounted(){
    if (this.$route.query['s'] == 'true'){
      this.search()
    }
  }
}
</script>
