<template>
  <div>
    <div class="container">
      <div class="p-md-5 mb-4 bg-white rounded-3 home-card-bg" id="home_home">
        <div class="container-fluid py-5">
          <h1 class="display-5 fw-bold">冰山一角</h1>
          <p class="col-md-8 fs-4">从世界各地传送到雪狐冰屋的信息数据都被整理好塞进冰山一角。</p>
        </div>
      </div>
      <div class="row align-items-md-stretch">
        <div class="col-md-6 pt-4" v-if="verified">
          <div class="h-100 p-5 text-white bg-success border rounded-3">
            <h2>欢迎！~</h2>
            <p>身份验证成功！~随意逛逛吧~</p>
            <p>{{greeting_text}}</p>
            <button class="btn btn-outline-light" @click="logout" type="button">退出登陆</button>
          </div>
        </div>
        <div class="col-md-6 pt-4" v-if="!verified">
          <div class="h-100 p-5 text-white bg-primary border rounded-3">
            <h2>欢迎！~</h2>
            <p>首次来冰山，或许你先要验证你的身份。</p>
            <b-input-group prepend="口令">
              <b-form-input placeholder="芝麻开门...?" v-model="password"></b-form-input>
              <b-input-group-append>
                <b-button variant="info" @click="verify" :disabled="loading">{{verify_text}}</b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <div class="col-md-6 pt-4" v-if="verified">
          <div class="h-100 p-5 text-white bg-dark rounded-3 home-card-bg"
            id="home_package"
          >
            <h2>信息数据总览</h2>
            <p>戳进这里，看看特定时间段内各类数据的汇总和排名</p>
            <b-button variant="outline-light" to="/data">戳我看总览！</b-button>
          </div>
        </div>
        <div class="col-md-6 pt-4" v-if="verified">
          <div class="h-100 p-5 text-white bg-secondary border rounded-3 home-card-bg"
            id="home_search"
          >
            <h2>搜索...</h2>
            <p>对特定观众发来的数据感兴趣？</p>
            <p>有个关键词，想找到对应的数据？戳我！</p>
            <b-button variant="outline-light" to="/search">戳我去搜索！</b-button>
          </div>
        </div>
        <div class="col-md-6 pt-4" v-if="verified">
          <div class="h-100 p-5 bg-white border rounded-3 home-card-bg"
            id="home_trend"
          >
            <h2>趋势</h2>
            <p>时间流逝，想看看信息数据的变化？戳我！</p>
            <b-button variant="outline-secondary" to="/trend">戳我寻趋势！</b-button>
          </div>
        </div>
        <div class="col-md-6 pt-4" v-if="verified">
          <div class="h-100 p-5 bg-white border rounded-3 home-card-bg"
               id="home_superchat"
          >
            <h2>SC大屏</h2>
            <p>醒目留言即时同步，直播现场错过SC？戳我！</p>
            <b-button variant="outline-secondary" to="">模块开发中</b-button>
          </div>
        </div>
        <div class="col-md-6 pt-4" v-if="verified">
          <div class="h-100 p-5 text-white bg-primary border rounded-3 home-card-bg"
            id="home_info"
          >
            <h2>关于/介绍</h2>
            <p>不知道冰山有什么功能？戳我！</p>
            <b-button variant="outline-light" to="/info">有问题戳我！</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var crypto = require('crypto')
var dayjs = require('dayjs')
import query from '@/query.js'
window.data.home = {
  password: '',
  loading: false,
  verified: false,
  greeting_text: '',
  greetings: [
    '今天有没有睡个好觉，做个好梦呢~',
    '不考虑那么多，冲！',
    '在你耳边轻轻说：你obs没点推流！~',
    '偶尔也要让身体放松一下哦~',
    'It’s the tip of the iceberg. ',
    '希望今天的狐狐工作也是顺顺利利的呀',
    '古有鲁班门前弄大斧，今有狐狐面前耍统计（误',
    'The best or nothing.',
    '直播辛苦了！早点休息！',
    '我们每天度过的所谓日常，也许是一个个接连不断发生的奇迹',
    '有一个人来到酒吧，点了份炒饭orz',
    '啊对对对'
  ]
}
var data = window.data.home
export default {
  name: 'Home',
  data: ()=>{
    return data
  },
  methods: {
    verify: function(){
      if (this.password=='') return
      let hash = crypto.createHash("sha512").update(this.password).digest("hex")
      this.loading = true
      query.verify(hash)
      return
    },
    logout: function(){
      this.$cookies.remove('password')
      this.verified = false
    }
  },
  computed: {
    verify_text: ()=>{
      if (data.loading) return '验证中...'
      else return '验证!'
    }
  },
  created: function(){
    if(this.$cookies.get('password') != null) this.verified = true
    let start = dayjs().startOf('date')
    let end = dayjs()
    let duration = end.diff(start)
    this.greeting_text = this.greetings[Math.floor(duration/(1000*60*10))%this.greetings.length]
  }
}
</script>

<style>
.home-card-bg{
  background-repeat: no-repeat;
  background-position: bottom right;
}
#home_home {
  background-image: url(~@/assets/logo.png);
  background-size: auto 70%;
}
#home_package {
  background-image: url(~@/assets/package.svg);
}
#home_trend {
  background-image: url(~@/assets/trend.svg);
}
#home_search {
  background-image: url(~@/assets/search.svg);
}
#home_info {
  background-image: url(~@/assets/info.svg);
}
#home_superchat {
  background-image: url(~@/assets/superchat.svg);
}
</style>