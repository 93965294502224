<template>
  <div>
    <b-form-group>
      <h5 class="pb-2">数据来源：</h5>
      <b-form-radio-group v-model="vid">
        <b-form-radio value="xuehu">雪狐</b-form-radio>
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
window.data.vup_picker = {
  vid: 'xuehu',
}
var data = window.data.vup_picker
export default {
  name: 'vup-picker',
  props: {
    exclude_all: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return data
  },
  created: function(){
    if (this.exclude_all && data.vid=="all") data.vid = "xuehu"
  }
}
</script>